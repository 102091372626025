<template>
  <span>
    {{ text }}
    <feather
      :id="id"
      class="text-info observations"
      type="info"></feather>
    <b-tooltip v-if="tooltipText"
               custom-class="observations-tooltip"
               :target="id"
               placement="bottom">{{ tooltipText }}</b-tooltip>
  </span>
</template>

<script>
export default {
  name: 'FinancialTextWithIcon',
  props: {
    id: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
.observations {
  height: 18px;
  width: 18px;
}

.observations-tooltip > .tooltip-inner {
  white-space: pre-line;
  max-width: none !important;
}
</style>
