<template>
  <div class="p-3">
    <header class="mb-4">
      <h3>{{ translations.list_page_title }}</h3>
    </header>
      <financial-list
        v-model="currentPage"
        :header-data="TABLE_HEADERS"
        :data="transactions"
        :disabled="loadingPage"
        :items-per-page="itemsPerPage"
        @page-changed="loadTransactionsPEPMList"
        :total="total"
        :no-items-message="translations.missing_transactions"/>
  </div>
</template>

<script>
import translations from '@/translations';
import { formatDatetime, formatValue } from '@/helpers/finance';
import FinancialList, {
  associateHeaderDataItem,
  generateCustomComponentColumn,
} from '@/components/Financial/FinancialList.vue';
import FinancialTextWithIcon from '@/components/Financial/TextWithIcon.vue';

export default {
  components: {
    FinancialList,
    // eslint-disable-next-line vue/no-unused-components
    FinancialTextWithIcon,
  },
  name: 'FinanceTransactionsPEPMList.vue',
  created() {
    this.TABLE_HEADERS = [
      associateHeaderDataItem(this.translations.transaction_id, 'id'),
      associateHeaderDataItem(this.translations.created_at, 'created_at'),
      associateHeaderDataItem(this.translations.client_name, 'client_name'),
      associateHeaderDataItem(this.translations.transaction_type, 'transaction_type'),
      associateHeaderDataItem(this.translations.value, 'transaction_value_observations'),
      associateHeaderDataItem(this.translations.value_type, 'transaction_value_type'),
      associateHeaderDataItem(this.translations.partner_name, 'partner_name'),
    ];
  },
  data() {
    return {
      translations: translations.finance.transactions_pepm,
      transactions: [],
      loadingPage: true,
      itemsPerPage: 10,
      currentPage: 1,
      total: 0,
    };
  },
  computed: {
    filters: {
      get() {
        return {
          offset: (this.currentPage - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
          order_by: 'id',
          order_descending: true,
        };
      },
    },
  },
  async beforeMount() {
    await this.loadTransactionsPEPMList();
  },
  methods: {
    async loadTransactionsPEPMList() {
      this.loadingPage = true;
      try {
        const { data } = await this.$store.dispatch('Financial/getTransactionsPEPM', { ...this.filters });

        if (!data) {
          this.total = 0;
          this.transactions = [];
        } else {
          this.total = data.total;
          this.transactions = data.transactions;
        }

        this.transactions.forEach(transaction => {
          transaction.created_at = formatDatetime(transaction.created_at);

          const formattedValue = formatValue(transaction.transaction_value);
          transaction.transaction_value_observations = generateCustomComponentColumn(FinancialTextWithIcon, {
            id: `t-${transaction.id}`,
            tooltipText: transaction.observations,
            text: formattedValue,
          }, formattedValue);
        });
        this.loadingPage = false;
      } catch (_) {
        this.$noty.error(this.translations.errors.fetch_list);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
